import Vue from 'vue'
import VueRouter from 'vue-router'

import Index from '../pages/index'
import Blank from '../pages/blank'
// import Login from '../pages/login'
import finish from '../pages/finish.vue'

//1 注入
Vue.use(VueRouter)

//2 定义路由
const routes = [
  // 添加默认映射
  {
    path: '/',
    redirect: '/index'
  },
  {
    path: '/index',
    name:'index',
    component: Index
  },
  {
    path: '/blank',
    component: Blank
  },
  {
    path: '/finish',
    name:'finish',
    component: finish
  },

]

//3 创建router实例
const router = new VueRouter({
  routes
})

//4 导出 (到main.js挂载)
export default router
