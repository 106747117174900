<template>
  <div class="page_cover">
    <div class="content_box">
      <div class="content_con">
        <img src="../assets/编组 3@2x.png" alt="">
      </div>
      <div class="content_title">提交成功</div>
      <div class="content" v-html="resultString"></div>
      <!-- <div class="content">您的车牌为苏D87488,免费停放时间为2023年1月10日00:00:00-2023年1月12日00:00:00</div> -->
    </div>
  </div>
</template>

<script>
export default {
  created(){
    const result=this.$route.params.content
    this.resultString=result
  },
  data(){
    return {
      resultString:''
    }
  }
}
</script>

<style lang="less" scoped> 
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.page_cover{
  width: 100%;
  height: 100%;
  background-color: #F5F6FB;
  .content_box{
    width: 100%;
    height: 5rem;
    // background-color: pink;
    margin-top: 3rem;

    .content_con{
      text-align: center;
      img{
        width: 1.7125rem;
        height:1.7125rem;
      }
    }
    .content_title{
      text-align: center;
      color: #191919;
      font-size: .435rem;
      font-weight: 700;
      padding-top: .3rem;
    }
    .content{
      padding: .4rem;
      text-align: center;
      font-size: .33rem;
      color: #7F8389;
    }
  }
}
</style>