import Vue from 'vue'
import Vant from 'vant'
import App from './App.vue'
import utils from './utils/utils'
import router from './router/router'
import 'vant/lib/index.css'
import { Table, TableColumn } from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@vant/touch-emulator';

Vue.prototype.$utils = utils

Vue.config.productionTip = false
Vue.use(Vant)
Vue.use(Table)
Vue.use(TableColumn)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
