<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {},
  mounted() {
    // 在mounted生命周期里执行这个方法，添加一个元素，我们在index.html内通过id判断这个元素
    this.$nextTick(() => {
      (function () {
        var appDom = document.getElementById('myapp')     //这里的app是我们vue项目的app
        var checkDom = document.createElement('span')
        checkDom.setAttribute('id', 'my-check-load-dom')   //这里的id是我们需要判断的
        checkDom.style.display = 'none'
        appDom.appendChild(checkDom)
      })()
    })

  }
}
</script>

<style lang="less" >
*{
  box-sizing: border-box;
}
html,
body {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: #F5F6FB;
  margin: 0;
  padding: 0;
}
/* 选中行取消高亮 */
.el-table__body tr.hover-row>td.el-table__cell {
  background: none !important;
}
</style>
