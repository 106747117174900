<template>
  <div class="page-cover">
    <div class="banner-box">
      <img class="bannner-img" src="../assets/banner@2x.png" alt="">
    </div>
    <van-form validate-first @failed="onFailed" @submit="onSubmit">
      <van-field v-model.trim="orderName"   name="orderName" label="宾客姓名" placeholder="请输入宾客姓名"
        :rules="[{ pattern, message: '请输入宾客姓名' }]" />
      <van-field v-model="carNumber" name="carNumber" label="车牌号" placeholder="请输入车牌号"
        :rules="[{ validator, message: '请输入正确的车牌' }]" readonly @focus="showPopup" />
      <div style="margin: 16px;">
        <van-button round block type="info" native-type="submit">提交</van-button>
      </div>
    </van-form>
    <van-popup v-model="showKeyboards" round position="bottom" :style="{ height: '72%' }">
      <Keyboards :showkeys="true" @sendCarNumber="getcrNumber"></Keyboards>
    </van-popup>
    <div class="loading">
      <van-overlay :show="showLoading" />
      <van-loading v-show="showLoading" color="#fff" style="z-index:999" />
    </div>

  </div>
</template>

<script>
import { removeAccessToken, removeAccessTokenExpiredTime } from '../utils/auth'
import { submitCarNumber } from '../api/global'
import Keyboards from '../components/keybords/index.vue'
import { Toast } from 'vant';
export default {
  name: 'index',
  components: { Keyboards },
  data() {
    return {
      showLoading: false,
      orderName: '',
      carNumber: '',
      pattern: /\S/,  //手机号正则
      showKeyboards: false,
    }
  },
  created() {
    // 清空上次的token
    removeAccessTokenExpiredTime()
    removeAccessToken()
  },
  methods: {
    validator(val) {
      // 校验函数返回 true 表示校验通过，false 表示不通过
      const carNumberReg = /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-HJ-NP-Z][A-HJ-NP-Z0-9]{4,5}[A-HJ-NP-Z0-9挂学警港澳]$/
      return carNumberReg.test(val);
    },
    getcrNumber(val) {
      console.log(val, 'page页面');
      const carNumberReg = /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-HJ-NP-Z][A-HJ-NP-Z0-9]{4,5}[A-HJ-NP-Z0-9挂学警港澳]$/
      if (carNumberReg.test(val)) {
        this.showKeyboards = false
        this.carNumber = val
      }
    },
    onSubmit(values) {
      const that=this
      console.log('submit', values);
      this.showLoading = true
      if (values.orderName && values.carNumber) {
        submitCarNumber(values.orderName, values.carNumber).then(res => {
          console.log(res);
          if(res.result===false){
            Toast.fail({
              message:res.message,
              position:'top'
            });
          }else{
            that.$router.push({ name: 'finish', params: { content: res.message } })
          }
        }).catch(err => {
          console.log(err);
          Toast.fail('提交失败');
        }).finally(() => {
          that.showLoading = false
          that.orderName = ''
          that.carNumber = ''
        })
      }
    },
    onFailed(errorInfo) {
      console.log('failed', errorInfo);
    },

    showPopup() {
      document.activeElement.blur()  // 禁用系统键盘
      this.showKeyboards = true;
    },
  }

}
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.page-cover {
  width: 100%;
  height: 100%;

  .banner-box {
    width: 100%;

    .bannner-img {
      width: 100%;
    }

  }

  .loading {
    width: 100%;
    position: absolute;
    margin-top: -150px;
    text-align: center;
  }
}


.van-cell {
  width: 93vw;
  margin: .225rem .24rem;

  /deep/.van-field__label {
    color: #000000;
    font-size: .32rem;
  }
}
/deep/.van-cell{
  padding: .225rem .24rem;
}

.van-button--info {
  background-color: #333333;
  border: 1px solid #333333;

  .van-button__text {
    color: #FFFFFF;
    font-size: .38rem;
  }

}

// /deep/.van-field__error-message {
  // display: none;
// }
</style>