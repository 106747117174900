import request from '@/utils/request'
// eslint-disable-next-line
import { Config } from './config'

const BASE_URL = Config.restUrl

export function loginNoPw (code) {
  return request({
    url: `${BASE_URL}core_organization/users/ding_user_get_by_code?code=${code}&dingType=0`,
    method: 'get'
  })
}

export function getNews (date, userId) {
  return request({
    url: `${BASE_URL}infrastructure_oa/news/get_by_applet?date=${date}&userId=${userId}`,
    method: 'get'
  })
}

// 获取标题和寄语
export function getNewsHeader (date) {
  return request({
    url: `${BASE_URL}infrastructure_oa/news/get_news_header?date=${date}`,
    method: 'get'
  })
}

// 签阅
export function appletSign (userId, date) {
  return request({
    url: `${BASE_URL}infrastructure_oa/news/${userId}/applet_sign/${date}`,
    method: 'post',
  })
}

// 获取编制信息
export function getNumberInspection (date) {
  return request({
    url: `${BASE_URL}core_organization/number_inspection/phone?dateTime=${date}`,
    method: 'get'
  })
}

// 获取营收信息
export function getDailyRevenue (date, userId) {
  return request({
    url: `${BASE_URL}business_tourism/revenueBriefing/get_applet_daily_revenue?date=${date}&uid=${userId}`,
    method: 'get'
  })
}

export function getGroups () {
  return request({
    url: `${BASE_URL}core_organization/post_demands/get_groups`,
    method: 'get'
  })
}

export function getGroupDetail (companyId, typeId) {
  return request({
    url: `${BASE_URL}core_organization/post_demands/get_group_detail/${typeId}?CompanyID=${companyId}`,
    method: 'get'
  })
}

export function createResult (params) {
  return request({
    url: `${BASE_URL}infrastructure_oa/survey/survey_record`,
    method: 'post',
    data: params
  })
}

export function getList () {
  return request({
    url: `${BASE_URL}infrastructure_oa/survey/1/100?IsUse=true`,
    method: 'get'
  })
}

export function getQuestions (id) {
  return request({
    url: `${BASE_URL}infrastructure_oa/survey/survey_questions/1/100?SurveyID=${id}`,
    method: 'get'
  })
}

// 停车系统接口
export function submitCarNumber(name,licensePlate){
  return request({
    url: `${BASE_URL}business_resource/order/bind_licenseplate?name=${name}&licensePlate=${licensePlate}`,
    method: 'post',
  })
}
