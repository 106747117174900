<template>
  <div class="keyboards">
    <div class="keyboards_title">点击输入车牌<span>(绿色背景为新能源车牌)</span> </div>
    <div>
      <ul class="input_box" @click="clickShowKeyboard">
        <li>{{ first }}</li>
        <li>{{ numArr[0] }}</li><span style="font-size: .5rem;line-height: .9rem;color:#ced0d2 ;">•</span>
        <li>{{ numArr[1] }}</li>
        <li>{{ numArr[2] }}</li>
        <li>{{ numArr[3] }}</li>
        <li>{{ numArr[4] }}</li>
        <li>{{ numArr[5] }}</li>
        <li>{{ numArr[6] }}</li>
      </ul>
    </div>
    <div class="keySure" @click="submitNumber">确定</div>
    <!-- 键盘-中英文 -->
    <van-popup v-model="showKeyboards" position="bottom" :overlay="false" overlay-class="displayNone">
      <div class="keyboards_keys">
        <div class="finish_key"><span @click="hidekeyboards">完成</span></div>
        <div class="finish_line"></div>
        <div class="key_box">
          <!-- 中文 -->
          <div v-if="checkLangages" class="chinese">
            <ul v-for="(item, index) in chineseList" :key="index">
              <li v-for="val in item" :key="val.id" :class="{ del: val.id === 99 || val.id === 98 }" @click="tabIn(val)">
                {{
                  val.name }}</li>
            </ul>
          </div>
          <!-- 数字英文 -->
          <div v-else class="english">
            <ul v-for="(item, index) in englishList" :key="index">
              <li v-for="val in item" :key="val.id" :class="{ del: val.id === 99 || val.id === 97 }" @click="tabIn(val)">
                {{
                  val.name }}</li>
            </ul>
          </div>

        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
// import { submitCarNumber } from '../../api/global'
import { Toast } from 'vant';

export default {
  props: ['showkeys'],
  data() {
    return {
      first: '',
      numArr: [],
      showKeyboards: true,
      checkLangages: true,
      chineseList: [
        [
          { name: "京", id: 1 },
          { name: "津", id: 2 },
          { name: "冀", id: 3 },
          { name: "晋", id: 4 },
          { name: "蒙", id: 5 },
          { name: "辽", id: 6 },
          { name: "吉", id: 7 },
          { name: "黑", id: 8 },
          { name: "沪", id: 9 },
          { name: "苏", id: 10 }
        ],
        [
          { name: "浙", id: 11 },
          { name: "皖", id: 12 },
          { name: "闽", id: 13 },
          { name: "赣", id: 14 },
          { name: "鲁", id: 15 },
          { name: "豫", id: 16 },
          { name: "鄂", id: 17 },
          { name: "湘", id: 18 },
          { name: "粤", id: 19 },
          { name: "桂", id: 20 },
        ],
        [
          { name: "琼", id: 21 },
          { name: "渝", id: 22 },
          { name: "川", id: 23 },
          { name: "贵", id: 24 },
          { name: "云", id: 25 },
          { name: "藏", id: 26 },
          { name: "陕", id: 27 },
          { name: "甘", id: 28 },
          { name: "青", id: 29 },
          { name: "宁", id: 30 },
        ],
        [
          { name: 'ABC', id: 98 },
          { name: "新", id: 31 },
          { name: "使", id: 32 },
          { name: "领", id: 33 },
          { name: "警", id: 34 },
          { name: "学", id: 35 },
          { name: "港", id: 36 },
          { name: "澳", id: 37 },
          { name: "删除", id: 99 }
        ]
      ],
      englishList: [
        [
          { name: "1", id: 40 },
          { name: "2", id: 41 },
          { name: "3", id: 42 },
          { name: "4", id: 43 },
          { name: "5", id: 44 },
          { name: "6", id: 45 },
          { name: "7", id: 46 },
          { name: "8", id: 47 },
          { name: "9", id: 48 },
          { name: "0", id: 49 },
        ],
        [
          { name: "Q", id: 38 },
          { name: "W", id: 39 },
          { name: "E", id: 40 },
          { name: "R", id: 41 },
          { name: "T", id: 42 },
          { name: "Y", id: 43 },
          { name: "U", id: 44 },
          { name: "I", id: 45 },
          { name: "O", id: 46 },
          { name: "P", id: 47 },
        ],
        [
          { name: "A", id: 48 },
          { name: "S", id: 49 },
          { name: "D", id: 50 },
          { name: "F", id: 51 },
          { name: "G", id: 52 },
          { name: "H", id: 53 },
          { name: "J", id: 54 },
          { name: "K", id: 55 },
          { name: "L", id: 56 },

        ],
        [
          { name: '地区', id: 97 },
          { name: "Z", id: 57 },
          { name: "X", id: 58 },
          { name: "C", id: 59 },
          { name: "V", id: 60 },
          { name: "B", id: 61 },
          { name: "N", id: 62 },
          { name: "M", id: 63 },
          { name: "删除", id: 99 }
        ]
      ],
      carNUmber: ''
    }
  },
  created(){
    this.showKeyboards=this.showkeys
  },
  methods: {
    // 唤醒键盘
    clickShowKeyboard() {
      this.showKeyboards = true
      if (!this.first) {
        this.checkLangages = true
      } else {
        this.checkLangages = false
      }
    },
    tabIn(val) {
      if (val.id === 98 || val.id === 97) {  //id为97或者98,中英文键盘切换
        this.checkLangages = !this.checkLangages
      } else if (val.id === 99) {  //id为99,删除操作
        if (this.numArr.length > 0) { //输入的数字数组不为空,
          this.numArr.pop() 
          this.carNUmber = this.first + this.numArr.join('')
        } else {  //数字输入的键盘为空
          this.first = "";
          this.checkLangages = true
        
        }
      } else { //输入操作
        if (this.first === '') {
          this.checkLangages=true
          this.first = val.name
          this.checkLangages=false
        }else{
          this.numArr.push(val.name)
        }
        this.carNUmber = this.first + this.numArr.join('')
      }
    },


    // 完成,隐藏键盘
    hidekeyboards() {
      this.showKeyboards = false
    },
    // 提交车牌号
    submitNumber() {
      const carNumberReg = /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-HJ-NP-Z][A-HJ-NP-Z0-9]{4,5}[A-HJ-NP-Z0-9挂学警港澳]$/
      if (carNumberReg.test(this.carNUmber)) {
        console.log(this.carNUmber, '确定按钮')
        // this.show_chinese = false;
        this.showKeyboards = false;
        this.$emit('sendCarNumber', this.carNUmber)
      } else {
        Toast('请输入正确的车牌号');
      }
      if (this.numArr.length >= 0) {
        this.checkLangages = false
      } else {
        this.checkLangages = true
      }

    }
  }

}
</script>

<style lang="less" scoped>
.keyboards {
  width: 100%;
  box-sizing: border-box;

  .keyboards_title {
    margin: .3rem .5rem;
    font-size: .35rem;

    span {
      margin-left: 5px;
      font-size: .3rem;
      color: #45b94d;
    }
  }

  .input_box {
    display: flex;
    justify-content: center;
    width: 6.5rem;
    height: .9rem;
    margin: 0 auto .5rem;
    box-sizing: border-box;

    li {
      flex: 1;
      border: 1px solid #ced0d2;
      border-radius: 4px;
      margin: 2px;
      text-align: center;
      line-height: .8rem;
      font-size: .45rem;
    }

    li:last-child {
      background-color: #9eefa4;
    }

    // 动态样式
    .li_focus {
      border: 1px solid orange;
    }


  }

  .keySure {
    margin: auto;
    font-size: .4rem;
    width: 6.5rem;
    height: .9rem;
    text-align: center;
    line-height: .9rem;
    color: #fff;
    border-radius: .45rem;
    background-color: #333333;
  }

  .keyboards_keys {
    width: 7.5rem;
    margin: .2rem auto 0rem;
    // padding-bottom: .5rem;
    height: 5.7rem;
    background-color: rgb(211, 214, 221);

    .finish_key {
      width: 7.5rem;
      font-size: .3rem;
      // font-weight: 700;
      height: .6rem;
      line-height: .6rem;
      color: rgb(104, 105, 105);

      span {
        position: absolute;
        right: .38rem;
      }
    }

    .finish_line {
      width: 7.5rem;
      border-bottom: 1px solid rgb(178, 179, 180);
    }

    .key_box {
      ul {
        display: flex;
        justify-content: center;
        width: 7rem;
        height: .9rem;
        margin: .2rem auto .2rem;
        box-sizing: border-box;

        li {
          // flex: 1;
          width: .63rem;
          font-size: .35rem;
          // border: 1px solid red;
          background-color: #fff;
          border-radius: 5px;
          box-shadow: 1px 3px 1px #aaa;
          text-align: center;
          line-height: .78rem;
          margin: 2px;
          padding: 2px;
        }

        .del {
          width: .96rem;
          background-color: rgb(177, 183, 197);
        }

        .area {
          width: .9rem;
        }
      }
    }
  }
}</style>