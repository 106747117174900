<template>
    <div></div>
</template>

<script>
    import {getQueryVariable} from "../utils/utils";
    import Cookies from 'js-cookie'
    import axios from 'axios'

    export default {
        name: "blank",
        // data: function(){
        //     return {
        //         testData: {}
        //     }
        // },
        created() {
            const code = getQueryVariable('code') ? getQueryVariable('code') : ''
            const userIdCookie = Cookies.get('userId1000049') ? Cookies.get('userId1000049') : ''
            if (!userIdCookie) {
                if (code) {
                    axios.get(`http://dailyreport.uu1.com/apis/wx_qy/query_user?code=${code}&agentid=1000049`).then((res) => {
                        Cookies.set('userNumber1000049', res.data.number, {expires: 14})
                        Cookies.set('userName1000049', res.data.trueName, {expires: 14})
                        Cookies.set('userId1000049', res.data.id, {expires: 14})
                        window.location.href = "http://dailyreport.uu1.com/news/#/index"
                    }).catch((err) => {
                        // reject()
                        alert(err)
                        this.$toast('参数异常 请稍后再试(errno: 10)')
                    })
                } else {
                    this.$toast('参数异常 请稍后再试(errno: 9)')
                    return false
                }
            } else {
                window.location.href = "http://dailyreport.uu1.com/news/#/index"
            }
        }
    }
</script>

<style scoped>

</style>
